import { Box, Text, Flex, Heading, Image, Button } from '@chakra-ui/react';
import { BsDroplet } from "react-icons/bs";
import { SiTelegram } from 'react-icons/si';
import { sendLandingPageViewed, sendStartChatClicked } from '../telemetry';
import { useEffect } from 'react';

const backgroundColor1 = "radial-gradient(circle, purple.300 30%, white 75%)";
const backgroundColor2 = "radial-gradient(circle, pink.300 60%, white 75%)";

const START_CHATTING = "Go to chat";

export const TELEGRAM_BOT_URL = "https://t.me/DreamGFXXXBot?start=superfunn";

function Home() {
  useEffect(() => {
    sendLandingPageViewed();
  }, []);

  return (
    <Box
      minH="100vh"
      backgroundImage={`${backgroundColor1}, ${backgroundColor2}`}
      backgroundBlendMode="overlay"
      textAlign="center"
      fontSize={{ base: "sm", md: "md" }}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        minH="100vh"
        flexDirection="column"
        padding={{ base: "10", md: "20" }}
      >
        <Box
          borderRadius="full"
          px={{ base: 3, md: 6 }}
          py={{ base: 1, md: 3 }}
          boxShadow="md"
          backgroundColor="transparent"
          border="1px solid"
          borderColor="gray.700"
          transition="all 0.2s"
        >
          <Flex alignItems="center" justifyContent="center">
            <BsDroplet fontSize={{ base: "xl", md: "2xl" }} color="gray.700" />
            <Text
              fontSize={{ base: "lg", md: "xl" }}
              color="gray.700"
              mr={{ base: 1, md: 2 }}
              ml={{ base: 1, md: 2 }}
            >
              Superfunn
            </Text>
          </Flex>
        </Box>
        <Heading
          color="gray.700"
          mt={{ base: 2, md: 4 }}
          size={{ base: "2xl", md: "4xl" }}
        >
          AI Girlfriend
        </Heading>
        <Text
          fontSize={{ base: "xl", md: "2xl" }}
          color="gray.700"
          mt={{ base: 2, md: 4 }}
          mb={{ base: 2, md: 4 }}
        >
          100% Free AI Girlfriend Generator <br/> No Email Required
        </Text>
        <Button
          as="a"
          href={TELEGRAM_BOT_URL}
          target="_blank"
          rel="noopener noreferrer"
          bg="pink.400"
          color="white"
          width={{ base: "180px", md: "250px" }}
          height={{ base: "40px", md: "75px" }}
          px={{ base: 3, md: 6 }}
          fontSize={{ base: "md", md: "2xl" }}
          rightIcon={<SiTelegram />}
          _hover={{
            bg: "purple.400",
          }}
          borderRadius="full"
          mb={{ base: "4", md: "12" }}
          onClick={() => sendStartChatClicked()}
        >
          {START_CHATTING}
        </Button>
        <Flex
          justifyContent="center"
          alignItems="center"
          p={{ base: 2, md: 4 }}
          borderRadius="3xl"
          bg="white"
          display="flex"
          flexDirection="column"
        >
          <Image
            src="/superfunn-landing-image.png"
            alt="Superfunn Girlfriend"
            borderRadius="3xl"
            width={{ base: "250px", md: "400px" }}
            marginBottom={{ base: "20px", md: "30px" }}
            marginTop={{ base: "20px", md: "30px" }}
            marginLeft={{ base: "10px", md: "15px" }}
            marginRight={{ base: "10px", md: "15px" }}
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default Home;